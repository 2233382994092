














































import { Research } from '@app/models';
import { Vue, Component, Prop } from 'vue-property-decorator';

import DropdownLanguage from '../../components/6-other/dropdown-language.vue';

@Component({
  components: { DropdownLanguage },
})
export default class ResearchDetailsB extends Vue {
  @Prop()
  public value!: Research.Mutable;

  @Prop()
  public language!: string;

  public get i18nLanguage() {
    return this.language !== this.$FEATURES.languages[0]
      ? this.language
      : undefined;
  }

  public getValue(key: string): string[] {
    if (!this.i18nLanguage) return (this.value as any)[key] || [];

    const value =
      this.value.i18n &&
      this.value.i18n[this.i18nLanguage] &&
      (this.value.i18n[this.i18nLanguage] as any)[key];
    return value || [];
  }

  public setValue(key: string, value: string[]) {
    if (!this.i18nLanguage) {
      (this.value as any)[key] = value;
      return;
    }

    this.value.i18n = {
      ...(this.value.i18n || {}),
      [this.i18nLanguage]: {
        ...((this.value.i18n && this.value.i18n[this.i18nLanguage]) || {}),
        [key]: value,
      },
    };
  }
}
